@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
    background-color: #222222;
  }
}

// body::-webkit-scrollbar {
//   width: 7.5px;
// }

@font-face {
  font-family: "PP Neue Machina";
  font-style: normal;
  font-weight: 800;
  font-size: 112px;
  line-height: 118%;
  src: url("../public/fonts/PPNeueMachina/PPNeueMachina-InktrapUltrabold.otf");
}

.blogText {
  font-size: 1.1rem;
  line-height: 1.7rem;
}

#figure {
  transition: all 0.4s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .textImageBlock {
    width: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: -24px;
    padding-right: -24px;
    overflow-x: hidden;
  }
}

#customImage {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#content,
#form {
  padding-top: 130px;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}

.activeCaseLink {
  position: relative;
}

.activeCaseLink::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  height: 100%;
  border-left: 4px solid #93a4ff;
}

.history {
  -webkit-mask-image: inear-gradient(
    180deg,
    transparent 5%,
    rgba(0, 0, 0, 1) 52%,
    rgba(0, 0, 0, 1) 44%,
    transparent 95%
  );
  mask-image: linear-gradient(
    180deg,
    transparent 5%,
    rgba(0, 0, 0, 1) 52%,
    rgba(0, 0, 0, 1) 44%,
    transparent 95%
  );
  height: 500px;
  overflow-y: scroll;
}

.outlineCustom {
  position: relative;
  border-radius: 8px;
}
.outlineCustom:hover::before {
  content: "";
  position: absolute;
  top: -3px; // border: 2px + offset: 1px
  right: -3px; // border: 2px + offset: 1px
  bottom: -3px; // border: 2px + offset: 1px
  left: -3px; // border: 2px + offset: 1px
  border: 4px solid #93a4ff;
  border-radius: 9px; // border—radius: 6px + offset: 1px
}
